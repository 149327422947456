const SORTING_OPTIONS = [
  {
    label: 'Year (Ascending)',
    id: 'YEAR_ASC',
  },
  {
    label: 'Year (Descending)',
    id: 'YEAR_DESC',
  },
  {
    label: 'Make (A to Z)',
    id: 'MAKE_AZ',
  },
  {
    label: 'Make (Z to A)',
    id: 'MAKE_ZA',
  },
  {
    label: 'Estimate (Low to High)',
    id: 'ESTIMATE_LH',
  },
  {
    label: 'Estimate (High to Low)',
    id: 'ESTIMATE_HL',
  },
];

const ITEMS = [
  {
    title: 'LOT 001',
    src:
      'https://images.ctfassets.net/moe5wzpj72u9/1txEfCk3i3ClVh1M12WjEG/ca43a9f3653c56f2516edd9e8fa060fa/1934_Bugatti_Type_59_Sports-04.jpg?w=2700&h=1800&q=90&fm=webp',
  },
  {
    title: 'LOT 002',
    src:
      'https://images.ctfassets.net/moe5wzpj72u9/fgWanJg7KBWxy5Cwj8QWi/9b7dc448ba4fba3ba035311d2b43b1da/1961_Aston_Martin_DB4_GT_Zagato-09.jpg?w=2700&h=1800&q=90&fm=webp',
  },
  {
    title: 'LOT 003',
    src:
      'https://images.ctfassets.net/moe5wzpj72u9/1txEfCk3i3ClVh1M12WjEG/ca43a9f3653c56f2516edd9e8fa060fa/1934_Bugatti_Type_59_Sports-04.jpg?w=2700&h=1800&q=90&fm=webp',
  },
];

export { SORTING_OPTIONS, ITEMS };
